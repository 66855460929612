var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "mt-10 col-12 mx-0",
      staticStyle: { "padding-top": "50px", "padding-bottom": "50px" },
      attrs: { id: "about-us-container" },
    },
    [
      _c("iframe", {
        attrs: {
          id: "writersIframeId",
          width: "100%",
          height: "600",
          src: "https://writers.ucarok.com/careers",
          frameborder: "0",
          scrolling: "no",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }